import React from "react";
// import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";

const Page_header = ({ Subheader, headertitle, backgroundImage }) => {
	// const { rpdata } = useContext(GlobalDataContext);
  return (
    <div
      className="breadcrumb-area bg-overlay"
      style={{ backgroundImage: `url("${backgroundImage})"` }}
    >
      <div className="container">
        <div className="breadcrumb-inner">
          <div className="section-title mb-0 text-center">
            <h2 className="page-title">{headertitle}</h2>
            <ul className="page-list">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{Subheader}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page_header;
